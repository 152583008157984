
import { Vue, Component, Watch, Ref } from 'vue-property-decorator'
import { lang } from '@/lang'
import { getAllDomains } from '@/sdk/domain'
import { wallet } from '@/utils/wallet'
import ReverRecordDialog from '../components/ReverRecordDialog.vue'
import ReverseRecord from '../components/ReverseRecord.vue'
import DomainList from '../components/DomainList.vue'
import { ensureSetup } from '@/sdk/base'
import * as events from 'events'
import { EventBus } from '@/utils/event'

@Component({
  components: {
    ReverseRecord,
    DomainList,
    ReverRecordDialog
  }
})
export default class MyRegistrant extends Vue {
  private loading = false

  private domainInfoList: Common.Domain.BaseInfoBackend[] = []

  private fatherDomainInfoList: Common.Domain.BaseInfoBackend[] = []

  @Ref() private dialog!: ReverRecordDialog

  private showDialog (currRecord: string) {
    if (!this.domainInfoList.length) return
    this.dialog.open(currRecord)
  }

  private get text () {
    return {
      title: lang('DOMAIN_NAME_DETAILS_REGISTRANT')
    }
  }

  private async getDomainList () {
    try {
      this.loading = true
      const list = await getAllDomains()
      if (list) {
        console.log('Domainslist', list)
        this.domainInfoList = list
        this.fatherDomainInfoList = list.filter(item => {
          return item.parent === '0x3fce7d1364a893e213bc4212792b517ffc88f5b13b86c8ef9c8d390c3a1370ce'
        })
      }
    } catch (e:any) {
      console.error('[app-getAllDomains]' + e.message)
    } finally {
      this.loading = false
    }
  }

  private get accountId (): string {
    return wallet.accountId
  }

  mounted (): void {
    if (this.accountId !== '') {
      this.getDomainList()
    }

    this.$eventBus.on(EventBus.Types.WALLET_CONNECT_SUCCEED, this.getDomainList)
    // this.$eventBus.on(EventBus.Types.DOMAIN_BASEINFO_REFETCH, this.getDomainList)

    this.$once('hook:beforeDestroy', () => {
      this.$eventBus.off(EventBus.Types.WALLET_CONNECT_SUCCEED, this.getDomainList)
      this.$eventBus.off(EventBus.Types.DOMAIN_BASEINFO_REFETCH, this.getDomainList)
    })
    ensureSetup(true)
  }

  @Watch('accountId')
  private accountIdChange (id: string, oldId: string) {
    if (id === '') return

    if (!id) return

    if (id) {
      this.getDomainList()
    }
  }
}
