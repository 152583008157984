
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { lang } from '@/lang'
import { getExpireDate } from '@/sdk/domain'
import { dateFormat } from '@/utils/common'

@Component({
  components: {
    NoData: () => import('./NoData.vue')
  }
})
export default class DomainList extends Vue {
  @Prop({ type: Array, default: () => [] })
  private domainInfoList!: Common.Domain.BaseInfoBackend[]

  private get text () {
    return {
      header_1: lang('DOMAIN_NAME_DOMAINS'),
      header_2: lang('DOMAIN_NAME_EXPIRE_DATE'),
      loading: lang('DOMAIN_NAME_LOADING')
    }
  }

  private get verifyBtnText () {
    return lang('VALID_BTN')
  }

  private get showVerifyButton () {
    return this.$route.query.userid && this.$route.query.chat
  }

  private handleClick (domain: string) {
    this.$router.push(`/details/${domain}`).catch(e => console.log('[router]:' + e.message))
  }

  private dateFormat (date: number) {
    return date === 0 ? '--' : dateFormat(new Date(date))
  }

  private gotoVerify (item: Common.Domain.BaseInfoBackend) {
    const path = `/details/${item.name}?tab=EDIT_RECORD&userid=${this.$route.query.userid}&chat=${this.$route.query.chat}`
    this.$router.push(path)
  }
}
